import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { IQueryAllResult } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import {
    layout,
    grid,
    wrapper,
    repeatTestCard,
    infoCard,
} from './champ-candidate-dashboard.module.scss';
import { ISection } from '../../models/section.model';
import { INavLink } from '../../models/nav-link.model';
import { IPage } from '../../models/page.model';
import useTranslations from '../../hooks/use-translations';
import { useCandidate } from '../../hooks/use-candidate';
import { usePagePathname } from '../../hooks/use-page-pathname';

import DashboardPanelCard from '../organisms/dashboard-panel-card';
import Section from '../hoc/section';
import Button from '../atoms/button';
import CandidateTestButton from '../atoms/candidate-test-button';

interface ICandidateDashboardSection extends ISection {
    items: {
        mainMenuLinks: INavLink[];
    };
}

interface ICandidateDashboardProps {
    section: ICandidateDashboardSection;
}

interface ICandidateDashboardQueryResult {
    allPage: IQueryAllResult<Pick<IPage, 'pathname' | 'type' | 'locale'>>;
}

const ChampCandidateDashboard: React.FC<ICandidateDashboardProps> = ({ section }) => {
    console.log(section);
    const {
        content: { buttons, texts },
    } = section;
    const [customTitleOne, customContentOne, customTitleTwo, customContentTwo] = texts;
    const [buttonOne, buttonTwo] = buttons;

    const t = useTranslations('ChampCandidateDashboard');
    const candidate = useCandidate({ queries: ['dashboard'] });
    const { allPage } = useStaticQuery<ICandidateDashboardQueryResult>(query);
    const candidateApplicationsPage = usePagePathname(allPage, 'candidate-applications');
    const candidateSuggestionsPage = usePagePathname(allPage, 'candidate-suggestions');
    const candidateRecommendationsPage = usePagePathname(allPage, 'candidate-recommendations');

    useEffect(() => {
        if (!candidate.dashboard.data) return;
        candidate.dashboard.refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Section
            className={layout}
            style={section.style}
            classes={{
                container: grid,
            }}
        >
            <div className={wrapper}>
                <DashboardPanelCard
                    title={t.applicationsTitle}
                    subtext={t.applicationsSubtext}
                    count={candidate.dashboard.data?.applies.toString()}
                >
                    {candidateApplicationsPage && (
                        <Button
                            to={candidateApplicationsPage}
                            as={'link'}
                            stylePreset={'secondary'}
                        >
                            <span>{t.see}</span>
                        </Button>
                    )}
                </DashboardPanelCard>
                <DashboardPanelCard
                    title={t.suggestionsTitle}
                    subtext={t.suggestionsSubtext}
                    count={candidate.dashboard.data?.suggestions.toString()}
                >
                    <Button to={candidateSuggestionsPage} as={'link'} stylePreset={'secondary'}>
                        <span>{t.see}</span>
                    </Button>
                </DashboardPanelCard>
                <DashboardPanelCard
                    title={t.recommendationsTitle}
                    count={`${
                        candidate.dashboard.data ? candidate.dashboard.data.recommendation : ''
                    }%`}
                >
                    <Button to={candidateRecommendationsPage} as={'link'} stylePreset={'secondary'}>
                        <span>{t.see}</span>
                    </Button>
                </DashboardPanelCard>
                <DashboardPanelCard title={t.repeatTest} className={repeatTestCard}>
                    <CandidateTestButton stylePreset="ctaAlt" />
                </DashboardPanelCard>
                <DashboardPanelCard
                    className={infoCard}
                    title={customTitleOne || ''}
                    text={customContentOne || ''}
                >
                    <Button href={buttonOne?.url || ''} as="externalLink" stylePreset={'ctaAlt'}>
                        <span>{t.check}</span>
                    </Button>
                </DashboardPanelCard>
                <DashboardPanelCard
                    className={infoCard}
                    title={customTitleTwo || ''}
                    text={customContentTwo || ''}
                >
                    <Button href={buttonTwo?.url || ''} as="externalLink" stylePreset={'ctaAlt'}>
                        <span>{t.check}</span>
                    </Button>
                </DashboardPanelCard>
            </div>
        </Section>
    );
};

const query = graphql`
    query {
        allPage(
            filter: {
                type: {
                    in: [
                        "home"
                        "candidate-applications"
                        "candidate-suggestions"
                        "candidate-recommendations"
                    ]
                }
            }
        ) {
            edges {
                node {
                    locale
                    pathname
                    type
                }
            }
        }
    }
`;

export default ChampCandidateDashboard;
