import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import { IQueryAllResult } from '../../models/query-all-result.model';
import { IPage } from '../../models/page.model';
import { useCandidate } from '../../hooks/use-candidate';
import useTranslations from '../../hooks/use-translations';
import { usePagePathname } from '../../hooks/use-page-pathname';

import Button, { TButtonProps } from './button';

interface ICandidateTestButtonProps {
    className?: string;
    stylePreset?: TButtonProps['stylePreset'];
}

interface ICandidateTestButtonQueryResult {
    allPage: IQueryAllResult<Pick<IPage, 'pathname' | 'locale'>>;
}

const CandidateTestButton: React.FC<ICandidateTestButtonProps> = ({ className, stylePreset }) => {
    const t = useTranslations('CandidateTestButton');
    const candidate = useCandidate();
    const { allPage } = useStaticQuery<ICandidateTestButtonQueryResult>(query);
    const orderPathname = usePagePathname(allPage);

    if (candidate.data?.testRetryWaitForAdmin) {
        return (
            <Button className={className} stylePreset={stylePreset} isDisabled={true}>
                {t.waiting}
            </Button>
        );
    }

    if (candidate.data?.testRetryUrl) {
        return (
            <Button
                className={className}
                as="externalLink"
                href={candidate.data?.testRetryUrl}
                stylePreset={stylePreset}
            >
                {t.solve}
            </Button>
        );
    }

    return (
        <Button className={className} stylePreset={stylePreset} as="link" to={orderPathname}>
            {t.buy}
        </Button>
    );
};

const query = graphql`
    query {
        allPage(filter: { type: { eq: "candidate-order-create" } }) {
            edges {
                node {
                    locale
                    pathname
                }
            }
        }
    }
`;

export default CandidateTestButton;
